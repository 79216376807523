<template>
    <div>
        <el-form :inline="true" :model="formInline" >

            <el-form-item label="所属平台">
                <el-select v-model="formInline.platformId" placeholder="请选择所属平台"  class="inputWidth" clearable @change="platformChange" filterable>
                    <el-option :label="item.platformName" :value="item.platformId" v-for="(item,index) in platformList" :key="index"></el-option>
                </el-select>
            </el-form-item>

<!--            <el-form-item label="店铺名称">-->
<!--                <el-input v-model="formInline.shopName" placeholder="请输入店铺名称" class="inputWidth" clearable></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="店铺名称">
                <el-select v-model="formInline.shopId" placeholder="请选择所属平台"  class="inputWidth" clearable filterable>
                    <el-option :label="item.shopName" :value="item.shopId" v-for="(item,index) in shopList" :key="index"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="商品编号">
                <el-input v-model="formInline.goodsNo" placeholder="请输入店铺名称" class="inputWidth" clearable></el-input>
            </el-form-item>

            <el-form-item label="商品状态">
                <el-select v-model="formInline.publishStatus" placeholder="请选择商品状态"  class="inputWidth" clearable>
                    <el-option label="下架" :value="0"></el-option>
                    <el-option label="上架" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit" icon="el-icon-search" >查询</el-button>
                <el-button type="primary" @click="reset" icon="el-icon-refresh">重置</el-button>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
    import {PLATFORM,SHOPSELECTEX} from '../../api/base'
    const formInline = {
        shopName: '',
        platformId: '',
        goodsNo: '',
        publishStatus: 1,
        shopId:'',
    };
    export default {
        name: "searForm",
        props:{
            // loading:{
            //     type:Boolean,
            //     default:()=>{
            //         return false;
            //     }
            // }
        },
        data() {
            return {
                formInline,
                platformList:[],
                shopList:[],
            }
        },
        methods: {
            platformChange(id){
                if(!id)return
                this.formInline.shopId = ''
                this.getShopList(id)
            },
            getShopList(platformId){
                this.$axios.get(SHOPSELECTEX,{platformId}).then(resp=>{
                    if(resp.code == 200){
                        this.shopList = resp.data;
                    }
                })
            },
            onSubmit(){
                this.$emit('onSubmit',this.formInline)
            },
            reset(){
                for(let key in this.formInline ){
                    this.formInline[key] = undefined;
                }
                this.times = []
                this.$emit('reset',formInline)
            },
            getPlatformList(){
                this.$axios.get(PLATFORM).then(resp=>{
                    if(resp.code == 200){
                        this.platformList = resp.data;
                    }
                })
            }
        },
        components: {

        },
        mounted() {
            this.getPlatformList()
        }
    }
</script>

<style scoped>
    .inputWidth{
        width: 230px;
    }
</style>