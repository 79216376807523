<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>宝贝管理</span>
			</div>
			<div class="addModel">
				<el-button type="primary" @click="addGoods()"><i class="el-icon-plus"></i>添加宝贝</el-button>
			</div>
			<div>
				<sb-sear
					@onSubmit="onSubmit"
					@reset="reset">
				</sb-sear>
			</div>
			<div class="goodscard">
				<el-table :data="tableData" border style="width: 100%; margin-top: 20px" v-loading="loading" row-key="goodsId">
					<el-table-column type="index" label="序号" width="50" align="center">
					</el-table-column>
					<el-table-column align="center" label="商品主图" prop="goodsPicture">
						<template slot-scope="scope">
							<sb-image :src="scope.row.goodsPicture" width="100px" height="100px"></sb-image>
						</template>
					</el-table-column>
					<el-table-column align="center" label="平台" prop="platformName">
					</el-table-column>

					<el-table-column prop="shopName" label="店铺名称" align="center">
					</el-table-column>

					<el-table-column align="center" label="商品编号" prop="goodsNo">
						<template slot-scope="scope">
							<el-link target="_blank" :underline="false" :href="scope.row.goodsLink">{{scope.row.goodsNo}}</el-link>
						</template>
					</el-table-column>

					<!--					<el-table-column prop="specDesc" label="尺码规格" align="center">-->
					<!--					</el-table-column>-->
					<el-table-column align="center" label="下单金额" prop="payablePrice">
					</el-table-column>
					<el-table-column align="center" label="展示价格" prop="displayPrice">
					</el-table-column>
					<el-table-column align="center" label="状态" prop="statusLabel" width="60">
					</el-table-column>
					<el-table-column align="center" label="操作">
						<template slot-scope="scope">
							<div class="operation">
								<div class="space">
									<el-link type="primary" @click="editgoods(scope.row)">修改</el-link>
									<el-link type="primary" @click="delGoods(scope.row)" v-if="scope.row.publishStatus==0">删除</el-link>
								</div>
								<!--								<el-link type="primary" @click="checkRank(scope.row)">排名查询</el-link>-->

								<div>
									<el-link
											@click="upGoods(scope.row)"
											style="color: green"
											type="primary" v-if="scope.row.publishStatus == 0">
										上架
									</el-link>
									<el-link
											@click="downGoods(scope.row)"
											style="color: red"
											type="primary" v-if="scope.row.publishStatus == 1">
										下架
									</el-link>
								</div>
								<div>

<!--									<el-link-->
<!--											@click="pushTask(scope.row)"-->
<!--											type="primary"-->
<!--											v-if="scope.row.publishStatus == 1">-->
<!--										发布任务-->
<!--									</el-link>-->

								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<sb-pagination
						:total="total"
						@handleCurrentChange="handleCurrentChange"
						@handleSizeChange="handleSizeChange">
				</sb-pagination>
			</div>
		</el-card>
		<sb-model :title="title" @successHandel="successHandel" ref="goodsModel"></sb-model>
	</div>
</template>

<script>
	import sbPagination from '../../components/pagination/pagination.vue';
	import sbModel from './operationModel.vue';
	import SbSear from './searForm.vue';
	import SbImage from '../../components/image/image.vue';
	import {GOODS, GOODSLOGIC, GOODSTATUS} from '../../api/base'

	export default {
		name: 'shopManagement',
		components: {
			sbPagination,
			sbModel,
			SbImage,
			SbSear,
		},
		data() {
			return {
				loading: false,
				title: '',
				tableData: [
					// {
					// 	actualAmt: 28,
					// 	goodsName: "免洗发膜护发素女改善毛躁补水免洗型柔护发神器头皮护理滋润膏",
					// 	goodsPhoto: "http://cdn.yingzhen99.com/jlbox/2021-03-24/80661785-ba0b-4557-ad6b-19d74400e01f.png",
					// 	goodsPrice: 28,
					// 	goodsUrl: "https://item.taobao.com/item.htm?spm=a2oq0.12575281.0.0.15d11debPZIxWn&ft=t&id=639735497393",
					// 	platform: "0",
					// 	shopName: "托尼潮色沙龙",
					// 	specDesc: "10包免洗发膜",
					// 	specFlag: "1",
					// }, {
					// 	actualAmt: 28,
					// 	goodsName: "免洗发膜护发素女改善毛躁补水免洗型柔护发神器头皮护理滋润膏",
					// 	goodsPhoto: "http://cdn.yingzhen99.com/jlbox/2021-03-24/80661785-ba0b-4557-ad6b-19d74400e01f.png",
					// 	goodsPrice: 28,
					// 	goodsUrl: "https://item.taobao.com/item.htm?spm=a2oq0.12575281.0.0.15d11debPZIxWn&ft=t&id=639735497393",
					// 	platform: "0",
					// 	shopName: "托尼潮色沙龙",
					// 	specDesc: "10包免洗发膜",
					// 	specFlag: "1",
					// }
				],
				requireForm: {
					pageSize: 10,
					pageNum: 1,
				},
				total: 0,

			}
		},
		mounted() {
			this.getGoodsList()
		},
		methods: {
			reset(form){
				for (let item in form){
					this.requireForm[item] = form[item]
				}
				this.requireForm.pageIndex = 1;
				this.getGoodsList()

			},
			onSubmit(form){
				for (let item in form){
					this.requireForm[item] = form[item]
				}
				this.requireForm.pageIndex = 1;
				this.getGoodsList()
			},
			pushTask() {
				this.$mes({message:'暂时不支持发布任务',	type:'warning'})
				// if (this.$route.path !== '/pageIndex/taskOne') {
				// 	this.$router.push('/pageIndex/taskOne')
				// }
			},
			upGoods({goodsId}) {
				this.$confirm('确定上架宝贝吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.put(`${GOODSTATUS}/${goodsId}`, {status: 1}).then(resp => {
						if (resp.code == 200) {
							this.getGoodsList()
							this.$mes({message: resp.msg})
						}
					})
				}).catch(() => {
					this.$mes({
						type: 'info',
						message: '已取消删除'
					})
				});
			},
			downGoods({goodsId}) {
				this.$confirm('确定下架宝贝吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.put(`${GOODSTATUS}/${goodsId}`, {status: 0}).then(resp => {
						if (resp.code == 200) {
							this.getGoodsList()
							this.$mes({message: resp.msg})
						}
					})
				}).catch(() => {
					this.$mes({
						type: 'info',
						message: '已取消删除'
					})
				});
			},
			successHandel() {
				this.requireForm.pageIndex = 1;
				this.getGoodsList()
			},
			getGoodsList() {
				this.loading = true;
				this.$axios.get(GOODS, this.requireForm).then(resp => {
					this.loading = false;
					if (resp.code == 200) {
						const data = resp.data;
						this.tableData = data.data;
						this.total = data.totalRow;
					}
				})
			},
			editgoods(row) {
				this.title = '修改宝贝'
				this.$refs.goodsModel.open(row)
			},
			delGoods({goodsId}) {
				this.$confirm('此操作将永久删除商品, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.delete(`${GOODSLOGIC}/${goodsId}`).then(resp => {
						if (resp.code == 200) {
							this.getGoodsList()
							this.$mes({message: resp.msg})
						}
					})
				}).catch(() => {
					this.$mes({
						type: 'info',
						message: '已取消删除'
					})
				});

			},
			checkRank() {

			},
			addGoods() {
				this.title = '添加宝贝'
				this.$refs.goodsModel.open()
			},
			handleSizeChange() {

			},
			handleCurrentChange(value) {
				this.requireForm.pageIndex = value;
				this.getGoodsList()
			},
		}
	}
</script>

<style lang="less" scoped>
	.operation{
		text-align: center;
		.space{
			display: flex;
			justify-content: space-around;
		}
	}
	.goodscard /deep/ .el-table__header-wrapper th{
		background-color: #F4F5F8 !important;
	}
	.copy {
		text-decoration: underline;

	}

	.box-card {
		width: 980px;
	}

	.addModel {
		margin-bottom: 30px;

		.el-button--primary {
			color: white;
			background-color: @primaryColorQian  !important;
			border-color: @primaryColorQian;
		}

		.el-button--primary:hover {
			background-color: #ffa68f !important;
			border-color: #ffa68f;
		}
	}
</style>
