<template>
	<div>
		<el-upload
				class="avatar-uploader"
				:action="uploadUrl"
				:show-file-list="false"
				:on-success="handleSuccess"
				:on-change="onChange"
				:data="{nameSpaces:uploadTypeList[type]}"
				accept="image/jpeg,image/jpg,image/png"
				name="file"
				:headers="{'x-auth-token':$store.getters['setting/userToken']}"
				:before-upload="beforeAvatarUpload">
			<!-- <img v-if="imageUrlCopy" :src="imageUrlCopy" class="avatar"> -->
			<div v-loading="loading">

				<sb-image v-if="imageUrlCopy" :src="imageUrlCopy" class="avatar" :imgStyle="imgStyle"
                  :width="imgStyle.width||'100px'"
                  :height="imgStyle.height||'100px'"
              >
				</sb-image>
				<div class="no-upload hoverHand" :style="{...imgStyle,lineHeight:imgStyle.height||'initial'}" v-else>
					<i class="iconfont icon-B" :style="{lineHeight:imgStyle.height||'initial'}"></i>
				</div>
      </div>
		</el-upload>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	/**
	 * handleSuccess {}    上传后钩子
	 * beforeAvatarUpload 上传前钩子
	 * imageUrl 回显图片路径
	 **/
	import SbImage from '../image/image.vue'
	import {UPLOADiMG} from '../../api/base'

	export default {
		data() {
			return {
				loading:false,

				imageUrlCopy: undefined,
				uploadTypeList: {
				},
			}
		},
		props: {
      //
			uploadUrl:{
				default:()=>{
					return process.env.VUE_APP_API_BASE_URL + '' + UPLOADiMG;
				}
			},
			type: {},
			imgStyle: {
				default: () => {
					return {}
				}
			},
			imageUrl: {
				default: () => {
					return undefined
				}
			}
		},
		watch: {
      imgStyle:{

      },
			imageUrl: {
				handler(newVal) {
          console.log(newVal)
					if (newVal && newVal.indexOf('http') == -1) {
						this.imageUrlCopy = process.env.VUE_APP_FILE_KEY + '/' + newVal
					} else {
						this.imageUrlCopy = newVal || undefined
					}
				},
				immediate: true,
			}
		},
		methods: {
			onChange(data){
				console.log(data)
				if(data.status =='uploading'||data.status =='ready'){
					this.loading = true;
				}else{
					this.loading = false;
				}
			},
			handleSuccess(res) {
				if(res.code != 200){
					this.$mes({message: res.msg, type: 'error'})
				}
				this.$emit('handleSuccess', res)
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 4;

				if (!isJPG) {
					this.$mes({message: '上传图片只能是 JPG，JPEG，PNG 格式!', type: 'error'})
				}
				if (!isLt2M) {
					this.$mes({message: '上传图片大小不能超过 4MB!', type: 'error'})
				}
				return isJPG && isLt2M;
			}
		},
		components: {
			SbImage,
		},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {
			this.uploadTypeList = this.$store.state.uploadTypeList;
		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>

	.no-upload{
		position: relative;
		border: 2px dashed rgb(221, 221, 221);
		border-radius: 6px;
		cursor: pointer;
    line-height: 100%;
		overflow: hidden;
		display: inline-block;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.no-upload {
		font-size: 28px;
		color: rgb(221, 221, 221);
		width: 93px;
		height: 93px;
		line-height: 93px;
		text-align: center;
	}

	.avatar {
		display: block;
	}

	.icon-B {
		color: rgb(221, 221, 221);
		font-size: 40px;
	}
</style>
