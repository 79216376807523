<template>
	<div>
		<div class="block">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="pageSize"
				layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pagination',
		props: {
			total: {
				type: Number,
				default: () => {
					return 0
				}
			},
			pageSize: {
				type: Number,
				default: () => {
					return 10
				}
			},
		},
		methods: {
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.$emit('handleSizeChange', val)

			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.$emit('handleCurrentChange', val)

			},
		}

	}
</script>

<style>
	.block {
		text-align: center;
	}
</style>
