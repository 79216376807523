<template>
	<div class="Goodsoperation">
		<el-dialog
				:title="title"
				:visible.sync="dialogVisible"
				width="951"
				:before-close="handleClose"
				:close-on-click-modal="false">
			<div>
				<el-form ref="form1" :model="form" label-width="130px" :rules="formRules" size="medium">

          <el-form-item label="店铺：" required prop="shopId">
            <el-select v-model="form.shopId" placeholder="请选择店铺">
              <el-option
                  :label="item.shopName"
                  :value="item.shopId"
                  v-for="(item,index) in shopList"
                  :key="index"
              >
              </el-option>
            </el-select>
          </el-form-item>

					<el-form-item label="商品类目：" required prop="categoryName">
						<el-cascader
								@change="cascaderChange"
								:options="categoryList"
								v-model="categoryArray"
								style="width:400px;"
								placeholder="请选择商品类目"
								:props="{
									value:'categoryId',
									label:'categoryName',
								}">
						</el-cascader>
					</el-form-item>

					<el-form-item label="商品名称：" required prop="goodsTitle">
						<el-input v-model="form.goodsTitle" placeholder="请输入商品名称" style="width: 500px;"></el-input>
					</el-form-item>

					<el-form-item label="商品链接：" required prop="goodsLink">
						<el-input v-model="form.goodsLink" placeholder="请输入商品链接" style="width: 500px;"></el-input>
					</el-form-item>

					<el-form-item label="商品主图：" required prop="goodsPicture">
						<div class="color: rgb(153, 153, 153);">确保与搜索展示的图片一致，支持PNG/JPEG-JPG格式文件，该图片用于买家接单页面的主图宝贝</div>
						<div>
							<sb-upload
                  :uploadUrl="uploadUrl"
									@handleSuccess="handleSuccess"
									:type="4"
                  :imgStyle="{width:'100px',height:'100px'}"
									:imageUrl="form.goodsPicture">
							</sb-upload>
						</div>
					</el-form-item>

<!--					<el-form-item label="颜色规格尺寸：" prop="specDesc">-->
					<!--						<el-radio-group v-model="form.specFlag" @change="radioChange">-->
					<!--							<el-radio label="0" name="任意规格">-->
					<!--								<span>任意规格</span>-->
					<!--							</el-radio>-->
					<!--							<el-radio label="1" name="自定义规格">-->
					<!--								<span>自定义规格</span>-->
					<!--								<el-input-->
					<!--										v-model="form.specDesc"-->
					<!--										class="specFlag"-->
					<!--										:disabled="form.specFlag == 0"-->
					<!--										placeholder="买家下单时选择的颜色、尺码、规格">-->

					<!--								</el-input>-->
					<!--							</el-radio>-->
					<!--						</el-radio-group>-->

					<!--					</el-form-item>-->

					<el-form-item label="拍下价格：" required prop="payablePrice">
						<el-input
								v-model="form.payablePrice"
								placeholder="请输入店铺名称"
								style="width: 180px;margin-right: 15px;">
						</el-input>
						元
					</el-form-item>

					<el-form-item label="展示价格：" required prop="displayPrice">
						<el-input
								v-model="form.displayPrice"
								placeholder="请输入店铺名称"
								style="width: 180px;margin-right: 15px;">

						</el-input>
						元
					</el-form-item>

				</el-form>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="save" :loading="loading">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	import SbUpload from '../../components/upload/upload.vue'
	import {SHOPSELECT, GOODS, CATEGORY} from "../../api/base";

	export default {
		data() {
			var validatePrice = (rule, value, callback) => {
				// console.log(value)
				if (/^\d+(\.\d{1,2})?$/.test(value) && value != 0) {
					// console.log('正确')
					callback();
				} else {
					return callback(new Error('请输入有效实际成交价且大于0'));
				}
			};
			var vilidatorDesc = (rule, value, callback) => {
				console.log(this.form.specFlag)
				console.log(value)
				
				if (this.form.specFlag == 1 && value == '') {
					return callback(new Error('请输入商品套餐'));
				} else {
					return callback();
				}
				
			};

			return {
        uploadUrl:process.env.VUE_APP_API_BASE_URL + '/upload/image/water',
				categoryArray: [],
				shopList: [],
				categoryList: [],
				dialogVisible: false,
				loading: false,
				required: true,
				form: {
					shopId: '',
					goodsLink: "",
          goodsPicture: "",
          watermarkPicture: "",

					goodsTitle: '',
					shopName: "",
					payablePrice: "",
					specDesc: "",
					displayPrice: "",

					oneCategoryId: '',
					twoCategoryId: '',
					threeCategoryId: '',
					categoryName: '',

				},
				formRules: {
					specFlag: [{
						required: false,
					}],
					specDesc: [{
						validator: vilidatorDesc,
					},],
					categoryName: [{
						required: true,
						message: '请选择商品类目',
						trigger: 'change'
					}],
					shopId: [{
						required: true,
						message: '请选择店铺',
						trigger: 'change'
					}],

					goodsTitle: [{
						required: true,
						message: '请输入商品名称',
						trigger: 'blur'
					}],
					goodsPicture: [{
						required: true,
						message: '请上传商品主图',
						trigger: 'change'
					}],
					goodsLink: [{
						required: true,
						message: '请输入商品链接',
						trigger: 'blur'
					}],

					displayPrice: [{
						validator: validatePrice,
						trigger: 'blur'
					}],
					payablePrice: [{
						validator: validatePrice,
						trigger: 'blur'
					}],
				}
			}
		},
		props: {
			title: {
				type: String,
				default: () => {
					return ''
				}
			},
			name: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		methods: {
			cascaderChange(value) {
				this.form.oneCategoryId = value[0];
				this.form.twoCategoryId = value[1];
				this.form.threeCategoryId = value[2];

				this.form.categoryName = this.getName(this.categoryList, value)

			},
			radioChange(value) {
				if (value == 0) this.form.specDesc = ''
				this.$refs.form1.validateField('specDesc')
			},
			handleSuccess(resp) {
				// console.log('上传图片成功返回')
        console.log(resp)
				if (resp.code == 200) {
          this.form.goodsPicture = resp.data[0].hash;
          this.form.watermarkPicture = resp.data[1].hash;
				}
			},
			save() {
				this.$refs.form1.validate((validate) => {
					// 验证通过
					if (validate) {
						this.loading = true;
						if (this.title == '添加宝贝') {
							this.$axios.post(GOODS, this.form).then(resp => {
								this.loading = false;
								if (resp.code == 200) {
									this.$mes({message: resp.msg})
									this.dialogVisible = false;
									this.$emit('successHandel')
								}
							}).catch(()=>{
                this.loading = false;
              })
						} else {
							this.$axios.put(`${GOODS}/${this.form.shopId}`, this.form).then(resp => {
								this.loading = false;
								if (resp.code == 200) {
									this.$mes({message: resp.msg});
									this.dialogVisible = false;
									this.$emit('successHandel')

								}
							}).catch(()=>{
                this.loading = false;
              })
						}

					}
				})
			},
			handleClose() {
				this.dialogVisible = false;
				this.$emit('handleClose');
			},
			// 递归 获取需要的分类名
			getName(arr, value) {
				let nameList = [];

				function foreach(arr, value) {
					for (let i = 0; i < arr.length; i++) {
						if (arr[i].categoryId == value[0]) {
							nameList.push(arr[i].categoryName)
							if (arr[i].children) {
								value.shift()
								foreach(arr[i].children, value)
							}
						}
					}
				}

				foreach(arr, value);
				return nameList.join('/')
			},
			// 递归 删除没用的数组
			deleteSpaceArray(arr) {
				arr.forEach(item => {
					if (item.children.length == 0) {
						delete item.children
					} else {
						this.deleteSpaceArray(item.children)
					}
				})
			},
			getCategory() {
				this.$axios.get(CATEGORY).then(resp => {
					console.log(resp)
					if (resp.code == 200) {
						this.categoryList = resp.data;
						this.deleteSpaceArray(this.categoryList)
					}
				})
			},
			getShopList() {
				this.$axios.get(SHOPSELECT).then(resp => {
					console.log(resp)
					if (resp.code == 200) {
						this.shopList = resp.data;
					}
				})
			},
			open(row) {
				this.dialogVisible = true;
				this.loading = false;
				this.categoryArray = []

				this.getShopList();
				this.getCategory();
				this.$nextTick(() => {
					if (row) {
						this.form.specFlag = '0';
						this.$refs.form1.resetFields()
						this.$refs.form1.clearValidate()

						Object.keys(row).forEach(item => {
							if (this.form[item] != undefined) {
								this.form[item] = row[item]
							}
						});

						this.categoryArray = [this.form.oneCategoryId, this.form.twoCategoryId, this.form.threeCategoryId]
						this.$refs.form1.clearValidate()
					} else {
						this.$refs.form1.resetFields()
					}
				})

			},
			stop() {
				this.dialogVisible = false;
			}
		},
		components: {
			SbUpload,
		},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	.Goodsoperation /deep/ .is-checked {
		color: @primaryColorQian;
	}

	.Goodsoperation .specFlag {
		margin-left: 15px;
	}

	/deep/ .el-select .el-input.is-focus .el-input__inner {
		border-color: @primaryColorQian;
	}

	/deep/ .el-input__inner:focus {
		border-color: @primaryColorQian;
	}
</style>
